import styled from "styled-components";

const Title = styled.h1`
  font-weight: 400;
  font-size: 1em;
  margin-top: 2em;

  &:first-child {
    margin-top: 0;
  }
`;

export default Title;
