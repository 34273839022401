import React, { useState } from "react";
import { useTransition, animated } from "react-spring";

// FIXME: Keep this DRY - very similar to BgTransition

interface ItemRefState {
  [key: string]: React.RefObject<any>;
}

interface ExtentCache {
  [key: string]: { clientWidth: number; clientHeight: number };
}

export const TextTransition = ({ children }: any) => {
  const [itemRefs, setItemRefs] = useState<ItemRefState>({});

  const child = React.Children.only(children);
  if (!itemRefs.hasOwnProperty(child.key)) {
    setItemRefs({ ...itemRefs, [child.key]: React.createRef() });
  }

  const transitions = useTransition(child, child => child.key, {
    from: { opacity: 0, transform: "translateY(-50px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(50px)", position: "absolute" }
  });

  /**
   * Sometimes (in about 10% of transitions) the ref to the item we're transitioning away from
   * is null. This causes the (now absolutely positioned) text to take on the full screen width,
   * which looks jarring. As a workaround we cache the extent of any item, so we can fall back
   * to that value if the ref is null. This should be fine in 99% of cases (as long as the screen
   * size is not changed).
   */
  const [extentCache, setExtentCache] = useState<ExtentCache>({});

  const maybeGetAbsoluteExtent = (key: string) => {
    const ref = itemRefs[key];

    // Update extent cache
    if (ref && ref.current) {
      if (
        !extentCache.hasOwnProperty(key) ||
        extentCache[key].clientWidth !== ref.current.clientWidth ||
        extentCache[key].clientHeight !== ref.current.clientHeight
      ) {
        setExtentCache({
          ...extentCache,
          [key]: {
            clientHeight: ref.current.clientHeight,
            clientWidth: ref.current.clientWidth
          }
        });
      }
    }

    // In order to stay responsive, we don't want to fix the size of the current item
    if (key === child.key) return null;

    let extent = ref.current;
    if (!ref || !ref.current) {
      if (extentCache.hasOwnProperty(key)) {
        extent = extentCache[key];
      } else {
        return null;
      }
    }
    return {
      height: extent.clientHeight,
      width: extent.clientWidth
    };
  };

  return (
    <React.Fragment>
      {transitions.map(({ item, key, props }) => (
        <animated.div
          key={key}
          style={{ ...props, ...maybeGetAbsoluteExtent(item.key) }}
        >
          <div ref={itemRefs[item.key]}>{item}</div>
        </animated.div>
      ))}
    </React.Fragment>
  );
};
