import React from "react";

import Title from "../Title";
import { StepNav } from "../StepNav";

const Psychotherapie = () => (
  <React.Fragment>
    <Title>Mit innerer Zustimmung leben</Title>
    <p>
      Die existenzanalytische Psychotherapie wurde in den 1930er Jahren vom
      Wiener Psychiater und Neurologen Viktor E. Frankl begründet und in der
      Gesellschaft für Logotherapie und Existenzanalyse vor allem durch seinen
      ehemaligen Mitarbeiter DDr. Alfried Längle weiterentwickelt – unter dessen
      persönlicher Leitung ich die Psychotherapie-Ausbildung absolvieren durfte.
    </p>
    <p>
      Existenzanalyse bedeutet Analyse der Bedingungen für ein wertfühlendes,
      selbstgestaltetes und menschenwürdiges Leben. Fragen wie: „Was ist
      eigentlich meine Welt, wer bin ich, was kann ich?“ helfen dabei, das
      Eigene zu finden. Ziel ist die Achtsamkeit sich selbst gegenüber sowie
      Weltoffenheit und Entschiedenheit des Sich-Einlassens.
    </p>
    <p>
      Die Existenzanalyse arbeitet somit an den personalen Voraussetzungen für
      ein sinnvolles Leben, wo diese durch seelische Krankheiten und Störungen
      verschüttet sind. Basis ist das Konzept der Grundmotivationen („Kann ich
      sein? Mag ich leben? Darf ich so sein? Wofür soll ich leben?“), die als
      "Bausteine der Existenz" systematisch im Beratungs- und Therapiegespräch
      eingesetzt werden.
    </p>
    <p>
      Jeder Mensch besitzt im Grunde die Fähigkeit, sich selbst zu helfen. Meine
      Aufgabe als Psychotherapeutin sehe ich darin, Ihnen diese Selbsthilfe und
      Selbstheilung zu ermöglichen.
    </p>

    <p>
      Nähere Informationen zur Existenzanalyse:{" "}
      <a href="http://www.gle.at" target="_blank" rel="noopener noreferrer">
        http://www.gle.at
      </a>
    </p>

    <p>Existenzanalytische Psychotherapie bietet Unterstützung bei:</p>
    <ul>
      <li>
        Lebens- und Übergangskrisen (Pubertät, Midlifecrisis, Pensionierung)
      </li>
      <li>Depressionen, Zwang, Angst- und Panik</li>
      <li>Psychosomatischen Beschwerden</li>
      <li>Beziehungsproblemen</li>
      <li>Sinnlosigkeitsgefühlen</li>
      <li>Schweren Erkrankungen</li>
      <li>Verlust und Trennung</li>
      <li>Trauer</li>
      <li>Problemen am Arbeitsplatz (Mobbing, Burnout)</li>
      <li>Sucht und Abhängigkeitserkrankungen</li>
      <li>Essstörungen</li>
    </ul>

    <blockquote>
      Erst der Mut zu sich selbst wird den Menschen seine Angst überwinden
      lassen
    </blockquote>
    <cite>
      - Viktor Frankl, (1905 - 1997), Begründer der Existenzanalyse und
      Logotherapie
    </cite>

    <StepNav />
  </React.Fragment>
);

export default Psychotherapie;
