import React from "react";
import { createBrowserRouter, createRender } from "found";
import { ScrollManager } from "found-scroll";

import App from "./App";
import Psychotherapie from "./content/Psychotherapie";
import Beratung from "./content/Beratung";
import Kosten from "./content/Kosten";
import UeberMich from "./content/UeberMich";
import Kontakt from "./content/Kontakt";
import { Image } from "./Image";
import { NavContext } from "./Nav";
import { StepNavContext } from "./StepNav";
// TODO: We need to <link rel="prefetch"> these to avoid funky transitions
// -> NOPE: Not supported by safari. SIGH
import es from "./es.jpg";
import angel from "./angel_bertinoro.jpg";
import trees from "./trees.jpg";
import rocks from "./rocks.jpg";
import grass from "./grass.jpg";

const EsBg = () => <Image src={es} />;
const TreesBg = () => <Image src={trees} />;
const AngelBg = () => <Image src={angel} />;
const RocksBg = () => <Image src={rocks} />;
const GrassBg = () => <Image src={grass} />;

const contentConfig = [
  { path: "/", label: "Psychotherapie", content: Psychotherapie, image: EsBg },
  { path: "/beratung", label: "Beratung", content: Beratung, image: GrassBg },
  {
    path: "/ueber-mich",
    label: "Über mich",
    content: UeberMich,
    image: AngelBg
  },
  { path: "/kosten", label: "Kosten", content: Kosten, image: RocksBg },
  { path: "/kontakt", label: "Kontakt", content: Kontakt, image: TreesBg }
];

let content = [];

for (let i = 0; i < contentConfig.length; ++i) {
  const prev = i > 0 ? contentConfig[i - 1] : null;
  const current = contentConfig[i];
  const next = i < contentConfig.length - 1 ? contentConfig[i + 1] : null;
  content[i] = {
    path: current.path,
    children: {
      content: [
        {
          Component: () => (
            <StepNavContext.Provider value={{ prev, next }}>
              {React.createElement(current.content)}
            </StepNavContext.Provider>
          )
        }
      ],
      image: [{ Component: current.image }]
    }
  };
}

const AppWithNavContext = (props: any) => (
  <NavContext.Provider value={contentConfig}>
    <App {...props} />
  </NavContext.Provider>
);

const render = createRender({
  renderError: ({ error }) => (
    <div>{error.status === 404 ? "Not found" : "Error"}</div>
  )
});

const Router = createBrowserRouter({
  routeConfig: [
    {
      path: "/",
      Component: AppWithNavContext,
      children: content
    }
  ],

  render: renderArgs => (
    <ScrollManager renderArgs={renderArgs}>{render(renderArgs)}</ScrollManager>
  )
});

export default Router;
