import React from "react";

import Title from "../Title";
import { StepNav } from "../StepNav";

const Psychotherapie = () => (
  <React.Fragment>
    <Title>Kosten</Title>
    <ul>
      <li>
        <strong>Psychotherapie:</strong> eine Einheit á 50 Min:{" "}
        <strong>€ 110,-</strong> Je nach Versicherung bekommen Sie einen Teil
        rückerstattet. Genauere Informationen hierzu und zur Möglichkeit der
        Sachleistung („Psychotherapie auf Krankenschein“) entnehmen Sie bitte{" "}
        <a
          href="https://www.psychotherapie-tirol.at/content/sozialversicherungen"
          target="_blank"
          rel="noopener noreferrer"
        >
          diesem Link
        </a>
        .
      </li>
      <li>
        Die Kosten für <strong>Einzelsupervision</strong> /{" "}
        <strong>Coaching</strong>, <strong>Fall- und Teamsupervision</strong>{" "}
        werden individuell vereinbart. Ich freue mich über Ihre Kontaktaufnahme.
      </li>
    </ul>
    <Title>Erstgespräch</Title>
    <p>
      Manche Ziele lassen sich in wenigen Gesprächen erreichen. In anderen
      Fällen ist es sinnvoll und notwendig, sich auf einen länger dauernden,
      tiefgreifenden Veränderungsprozess einzulassen. Im Erstgespräch lernen wir
      uns kennen, finden gemeinsam heraus welche Vorgangsweise am besten zu
      Ihrem Anliegen passt und klären die Rahmenbedingungen (Dauer, Kosten,
      Absageregelungen, etc.) für eine mögliche Zusammenarbeit.
    </p>
    <StepNav />
  </React.Fragment>
);

export default Psychotherapie;
