import React from "react";

import Title from "../Title";
import { StepNav } from "../StepNav";

const Psychotherapie = () => (
  <React.Fragment>
    <Title>Über mich</Title>
    <p>
      Nach einer handwerklich – kreativen Ausbildung und darauffolgender
      Tätigkeit in selbständiger und leitender Position habe ich mich - nicht
      zuletzt durch den neuen Blick auf die Welt, den ich durch das Mutter-Sein
      gewann - ziemlich radikal umorientiert. Fragen wie:
    </p>
    <p>
      <em>Was macht ein gelingendes Leben aus?</em>
      <br />
      <em>
        Wie entwickelt sich die Person zu der, die sie ihren Möglichkeiten nach
        ist? - und wie kann das gefördert werden?
      </em>
    </p>
    <p>
      ... führten mich schließlich zu einer Ausbildung in Biographie-Arbeit auf
      anthroposophischer Grundlage bei Joop Grün und Christian Althaus in
      Arlesheim in der Schweiz.
    </p>
    <p>
      Nach einer Zusatzausbildung in Berufs- und Bildungsberatung begann ich im
      Jahr 2000 mit der Begleitung und Beratung von Menschen in
      Umbruchs-Situationen wie Arbeitslosigkeit, Jobwechsel oder Wiedereinstieg.
      Während dieser Zeit arbeitete ich auch in der Fortbildung für
      Lehrlingsausbilder und hielt Seminare zu Themen wie Motivation, Führung
      oder Gestaltung der Lehr-Lernbeziehung.
    </p>
    <p>
      Parallel dazu unterstützte ich Jugendliche nach der Pflichtschulzeit oder
      nach einem Schul- bzw. Lehrabbruch bei der Berufswahl und der Suche nach
      einem Ausbildungsplatz. Die Erfahrungen mit diesen jungen Menschen mit zum
      Teil schwierigen Startbedingungen ins (Berufs-)leben ließen in mir den
      Entschluss zur Ausbildung zur Psychotherapeutin reifen.
    </p>
    <p>
      Nach dem Psychotherapeutischen Propädeutikum an der Universität Innsbruck
      absolvierte ich das Fachspezifikum in Existenzanalyse und Logotherapie bei
      Alfried Längle in Schlosshofen, Vorarlberg. Dem Masterstudium an der
      Donau-Universität Krems in Psychosozialer Beratung folgte eine
      Weiterbildung in Psychotrauma-Therapie u.a. bei Luise Reddemann, sowie
      Fortbildungen in „Generative-Coaching“ bei Stephen Gilligan, USA, und in
      Existenzanalytischer Behandlung von Essstörungen, um nur einige zu nennen.
    </p>
    <p>
      Ich bin Mitglied der Gesellschaft für Logotherapie und Existenzanalyse
      Österreich (GLE-Ö), des Österreichischen Bundesverbandes für
      Psychotherapie (ÖBVP), des Tiroler Landesverbandes für Psychotherapie
      (TLP) sowie des Netzwerks Essstörungen.
    </p>
    <iframe height="200px" width="100%" frameBorder="no" scrolling="no" seamless
      title="Erika Salzmann im Gespräch beim Tiroler Stimmen Podcast"
      src="https://player.simplecast.com/e5886bac-4d9a-402b-8f8c-2944ded743fd?dark=false" />
    <StepNav />
  </React.Fragment>
);

export default Psychotherapie;
