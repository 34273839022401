import React from "react";
import { hydrate, render } from "react-dom";
// TODO: Do we need a style reset?
import "./index.css";
import Router from "./Router";
// Since we use react-snap to prerender static HTML, we don't really need this.
// import registerServiceWorker from "./registerServiceWorker";

const root = document.getElementById("root");
if (root && root.hasChildNodes()) {
  hydrate(<Router />, root);
} else {
  render(<Router />, root);
}
// registerServiceWorker();
