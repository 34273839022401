import React from "react";

import Title from "../Title";
import { StepNav } from "../StepNav";

const Psychotherapie = () => (
  <React.Fragment>
    <Title>Coaching</Title>
    <p>
      Coaching ist eine individuelle Beratung bei beruflichen und privaten
      Fragestellungen. Es bedeutet stets Hilfe zur Selbsthilfe und
      Selbstverantwortung und der Entwicklung von Ressourcen. Ziel ist es, das
      Verhalten, die Wahrnehmung und das Erleben des Klienten / der Klientin zu
      verbessern und zu erweitern.
    </p>
    <Title>Biographiearbeit / Biographisches Coaching</Title>
    <p>
      Biographisches Coaching bzw. Biographiearbeit ist vor allem da hilfreich,
      wo es um die eigenständige Bewältigung von Lebenskrisen und um
      existentielle Fragen geht. Wenn die momentane Lebenssituation als
      unbefriedigend erlebt wird, man sich wiederholt in ähnlichen unerwünschten
      Konstellationen befindet, die Spuren im bisherigen Leben zusammenhanglos
      erscheinen oder Hindernisse den geplanten Weg verstellen, kann
      Biographisches Coaching zur Klärung beitragen.
    </p>
    <p>
      Im Zentrum der Arbeit steht die Reflexion der gegenwärtigen Situation auf
      dem Hintergrund der bisherigen Biographie. Wie der Maler von Zeit zu Zeit
      vom Bild zurücktritt um das große Ganze nicht aus dem Blick zu verlieren,
      wird in der Biographiearbeit durch das objektive Betrachten der momentanen
      Lage der Blick frei für Wesentliches und größere Zusammenhänge.
    </p>
    <p>
      Die Biographie als „ausgebreitetes Bild der Persönlichkeit“ kann Hinweise
      darauf geben, wer ich meinen Möglichkeiten nach bin und wie ich mein Leben
      dementsprechend aktiv in die Hand nehmen kann.
    </p>
    <Title>Supervision</Title>
    <blockquote>
      Nicht das Problem macht die Schwierigkeiten, sondern unsere Sichtweise.
    </blockquote>
    <cite>- Viktor Frankl</cite>
    <p>
      Für Menschen, die in psychosozialen und sozialpädagogischen Berufen oder
      im Gesundheits-bereich tätig sind, biete ich{" "}
      <strong>(Fall- ) Supervision</strong> an, in der schwierige und unklare
      Situationen in der Arbeit mit KlientInnen besprochen werden und die eigene
      Haltung reflektiert wird. Dabei wird durch genaue Wahrnehmung der Sachlage
      eine neue Sicht auf die Problematik erarbeitet und der Weg für Lösungen
      bereitet.
    </p>
    <p>
      In der <strong>Teamsupervision</strong> begleite ich Teams aus o.g.
      Berufsfeldern um in einer aus dem beruflichen Alltag herausgenommenen Zeit
      und in geschütztem Rahmen ihre berufliche Situation und ihr Miteinander zu
      reflektieren.
    </p>
    <p>
      Neben meiner Ausbildung als Biographieberaterin und Psychotherapeutin
      kommt mir dafür auch meine jahrelange Erfahrung in der sozialpädagogischen
      Arbeit sowie eine Weiterbildung in existenzanalytischer Teamsupervision /
      Coaching zugute.
    </p>
    <StepNav />
  </React.Fragment>
);

export default Psychotherapie;
