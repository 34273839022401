import React from "react";
import { withRouter, RouterState } from "found";
import styled from "styled-components";

import { Nav } from "./Nav";
import { TextTransition } from "./TextTransition";
import { ImageTransition } from "./ImageTransition";
import mapIcon from "./feathericons_map_pin.svg";
import phoneIcon from "./feathericons_phone.svg";

import es from "./es.jpg";
import angel from "./angel_bertinoro.jpg";
import trees from "./trees.jpg";
import rocks from "./rocks.jpg";
import grass from "./grass.jpg";

interface Props extends RouterState {
  content: React.ReactElement;
  image: React.ReactElement;
}

const AppLayout = styled.div`
  display: flex;

  @media (max-width: 600px) {
    /* Cut of nav to avoid fixed background to scale */
    overflow: hidden;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  & > div {
    max-width: 700px;
    margin: 4em;

    @media (max-width: 600px) {
      margin: 1em;
    }
  }

  p {
    margin: 1em 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const BackgroundContainer = styled.div`
  position: sticky;
  overflow: hidden;

  height: calc(100vh - 8em);
  top: 4em;
  margin: 4em;
  margin-left: 0;
  @media (min-width: 1600px) {
    height: calc(100vh - 16em);
    top: 8em;
    margin: 8em;
    margin-left: 0;
  }

  // TODO: How to maintain correct aspect ratio?
  max-width: 40%;

  // TODO: Use proper breakpoints (TWBS?)
  @media (max-width: 600px) {
    display: none;
  }
`;

const Header = styled.div`
  top: 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 1600px) {
    padding-top: 4em;
  }

  address {
    font-style: normal;

    // FIXME: Clean up
    > img {
      margin-top: -1px;
      vertical-align: text-top;

      &:last-child {
        margin-left: 1em;
      }
    }
  }
`;

const Title = styled.div.attrs({
  role: "title"
})`
  font-size: 2.5em;
  font-weight: 300;
  margin-top: -0.3em;

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;

const Tagline = styled.div`
  font-size: 1.8em;
  font-weight: 300;
  margin-top: -0.3em;
  margin-bottom: 0.8em;

  @media (max-width: 600px) {
    font-size: 1.4em;
    margin-bottom: 0.4em;
  }
`;

const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;

  @media (max-width: 600px) {
    margin-bottom: 1em;
  }
`;

const MobileHero = styled.div`
  overflow: hidden;
  margin-bottom: 1em;
  /* TODO: Use variables for these margins (this undoes the Content margin) */
  margin-left: -1em;
  margin-right: -1em;
  height: 40vh;

  @media (min-width: 601px) {
    display: none;
  }
`;

const TextBody = styled.div`
  font-size: 1.2em;
  font-weight: 300;
  text-align: justify;
`;

const ContactInfo = styled.address`
  display: flex;
  flex-wrap: wrap;

  a {
    color: #333;
    text-decoration: none;
  }

  img {
    vertical-align: text-top;
  }

  /* add spacing in a way that also works when wrapped on mobile */
  div {
    margin-top: 0.5em;

    &:first-child {
      margin-right: 1em;
    }
  }
`;

class App extends React.Component<Props> {
  render() {
    const pathKey = this.props.match.location.pathname;
    return (
      <AppLayout>
        <Content>
          <div>
            {/* Preload all images to avoid janky loads */}
            {/* FIXME: Not the cleanest of solutions */}
            <img src={angel} style={{ display: "none" }} alt="" />
            <img src={es} style={{ display: "none" }} alt="" />
            <img src={trees} style={{ display: "none" }} alt="" />
            <img src={rocks} style={{ display: "none" }} alt="" />
            <img src={grass} style={{ display: "none" }} alt="" />
            <Header>
              <Title>Erika Salzmann</Title>
              <Tagline>Psychotherapeutin</Tagline>
              <SpaceBetween>
                <ContactInfo>
                  <div>
                    <img src={phoneIcon} alt="" />{" "}
                    <a href="tel:+43 676 93 45 100">+43 676 93 45 100</a>
                  </div>
                  <div>
                    <img src={mapIcon} alt="" /> Maximilianstraße 2 &nbsp; 3.
                    Stock &nbsp; 375
                  </div>
                </ContactInfo>
                <Nav />
              </SpaceBetween>
              <MobileHero>
                <ImageTransition>
                  {React.cloneElement(this.props.image, { key: pathKey })}
                </ImageTransition>
              </MobileHero>
            </Header>
            <TextTransition>
              <TextBody key={pathKey}>{this.props.content}</TextBody>
            </TextTransition>
          </div>
        </Content>
        <BackgroundContainer>
          <ImageTransition>
            {React.cloneElement(this.props.image, { key: pathKey })}
          </ImageTransition>
        </BackgroundContainer>
      </AppLayout>
    );
  }
}

export default withRouter(App);
