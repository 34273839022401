import React from "react";
import styled from "styled-components";

// Since in 2019 there still is no way of having an element be constrained
// to a given aspect ratio (and scale with height), we have to use a transparent
// helper PNG that does the job.
import imageAspectRatio from "./image_aspect_ratio.png";

interface Props {
  src: string;
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const AspectRatio = styled.img.attrs({ alt: "" })`
  height: 100%;
`;

const BackgroundImage = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
`;

export class Image extends React.Component<Props> {
  render() {
    return (
      <Wrapper>
        <AspectRatio src={imageAspectRatio} />
        <BackgroundImage src={this.props.src} />
      </Wrapper>
    );
  }
}
