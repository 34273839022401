import React, { useState, useEffect, useCallback, useRef } from "react";
import Link from "found/lib/Link";
import { useRouter } from "found";
import styled, { css } from "styled-components";

type NavContextProps = Array<{ path: string; label: string }>;

export const NavContext = React.createContext<NavContextProps>([]);

const StyledLink = styled(Link).attrs({
  activeClassName: "active"
})`
  font-weight: 300;
  &.active {
    font-weight: 400;
  }
`;

const hamburgerActiveCSS = css`
  background: white;
  transition-delay: 0ms;
`;

const navVisibleCSS = css`
  visibility: visible;
  pointer-events: auto;

  li {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 50ms;
  }
`;

const navBgVisibleCSS = css`
  width: 400px;
  height: 400px;
  transition-delay: 0ms;
`;

const Hamburger = styled.div`
  position: relative;

  & > div {
    position: relative;
    width: 25px;
    height: 4px;
    margin: 2px 0;
    background: black;
    border-radius: 999em;
    z-index: 2;
    cursor: pointer;
    transition: background-color 50ms ease-in-out;
    transition-delay: 400ms;
  }

  @media(hover: hover) {
    &:hover > div {
      ${hamburgerActiveCSS}
    }
  }
  &.active > div {
    ${hamburgerActiveCSS}
  }

  nav {
    visibility:: hidden;
    pointer-events: none;
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 2;
    font-size: 1.2em;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      color: white;
      text-align: right;

      li {
        opacity: 0;
        transform: translateY(-15px);
        transition: all ease-in-out 100ms;
        transition-delay: 200ms;
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  @media(hover: hover) {
    &:hover nav {
      ${navVisibleCSS}
    }
  }
  &.active nav {
    ${navVisibleCSS}
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
    background: #ff0038;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    transition: all 250ms cubic-bezier(0.36, -0.07, 0.04, 1.31);
    transition-delay: 300ms;
  }

  @media(hover: hover) {
    &:hover::after {
      ${navBgVisibleCSS}
    }
  }
  &.active::after {
    ${navBgVisibleCSS}
  }
`;

/**
 * This component takes care of making the hamburger menu usable on touch devices.
 * Instead of showing the menu on hover, touch events are used to simulate similar
 * behavior.
 */
const TapToggle = ({ children }: { children: React.ReactElement }) => {
  const [active, setActive] = useState(false);
  // Tapping the hamburger sometimes causes an accidental click on the first nav item.
  // We thus block any transitions for a short period after the nav has been toggled.
  const [blockTransition, setBlockTransition] = useState(false);
  const childRef = useRef<Node>(null);

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      const path = e.composedPath();
      if (childRef.current !== null && path.includes(childRef.current)) return;
      setActive(false);
    };
    document.addEventListener("touchstart", handleTouchStart);
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  });

  const toggle = useCallback(() => {
    if (!active) {
      setBlockTransition(true);
      window.setTimeout(() => {
        setBlockTransition(false);
      }, 150);
    }

    setActive(!active);
  }, [active]);

  const { router } = useRouter();

  useEffect(() => {
    return router.addTransitionHook(() => {
      if (blockTransition) {
        return false;
      }
      setActive(false);
      return true;
    });
  });

  return (
    <div onTouchStart={toggle}>
      {React.cloneElement(children, {
        ref: childRef,
        className: active ? "active" : ""
      })}
    </div>
  );
};

export const Nav = () => {
  // We have to prevent bubbling of touch events on mobile, as we otherwise
  // interfere with TapToggle's hover emulation.
  const stopBubbling = (e: React.TouchEvent) => e.stopPropagation();
  return (
    <TapToggle>
      <Hamburger>
        <div />
        <div />
        <div />
        <nav>
          <ul>
            <NavContext.Consumer>
              {navItems =>
                navItems.map(ni => (
                  <li key={ni.path}>
                    <StyledLink
                      onTouchStart={stopBubbling}
                      exact={ni.path === "/"}
                      to={ni.path}
                    >
                      {ni.label}
                    </StyledLink>
                  </li>
                ))
              }
            </NavContext.Consumer>
          </ul>
        </nav>
      </Hamburger>
    </TapToggle>
  );
};
