import React, { useState } from "react";
import { useTransition, animated } from "react-spring";

// FIXME: Keep this DRY - very similar to TextTransition

interface ItemRefState {
  [key: string]: React.RefObject<any>;
}

export const ImageTransition = ({ children }: any) => {
  const [itemRefs, setItemRefs] = useState<ItemRefState>({});

  const child = React.Children.only(children);
  if (!itemRefs.hasOwnProperty(child.key)) {
    setItemRefs({ ...itemRefs, [child.key]: React.createRef() });
  }

  const transitions = useTransition(child, child => child.key, {
    from: { opacity: 0, transform: "scale(1.2)" },
    enter: { opacity: 1, transform: "scale(1.0)" },
    leave: { opacity: 0, transform: "scale(0.8)", position: "absolute" }
  });

  const maybeGetAbsoluteExtent = (key: string) => {
    // In order to stay responsive, we don't want to fix the size of the current item
    if (key === child.key) return null;
    const ref = itemRefs[key];
    if (!ref || !ref.current) return null;
    return {
      height: ref.current.clientHeight,
      width: ref.current.clientWidth
    };
  };

  return (
    <React.Fragment>
      {transitions.map(({ item, key, props }) => (
        <animated.div
          key={key}
          style={{
            ...props,
            height: "100%",
            ...maybeGetAbsoluteExtent(item.key)
          }}
        >
          <div ref={itemRefs[item.key]} style={{ height: "100%" }}>
            {item}
          </div>
        </animated.div>
      ))}
    </React.Fragment>
  );
};
