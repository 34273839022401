import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "found";

import arrowLeft from "./feathericons_arrow_left.svg";
import arrowRight from "./feathericons_arrow_right.svg";

interface StepNavContextProps {
  prev: { path: string; label: string } | null;
  next: { path: string; label: string } | null;
}

export const StepNavContext = React.createContext<StepNavContextProps>({
  prev: null,
  next: null
});

const LeftRight = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(3px);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;

  a {
    text-decoration: none;
    font-weight: 400;
    color: #333;

    img {
      vertical-align: text-bottom;
      animation: ${LeftRight} 2000ms infinite ease-in-out;
    }
  }
`;

export const StepNav = () => (
  <StepNavContext.Consumer>
    {({ prev, next }: StepNavContextProps) => (
      <Wrapper>
        {prev && (
          <Link to={prev.path}>
            <img src={arrowLeft} alt="" /> {prev.label}
          </Link>
        )}
        {!prev && <div />}
        {next && (
          <Link to={next.path}>
            {next.label} <img src={arrowRight} alt="" />
          </Link>
        )}
        {!next && <div />}
      </Wrapper>
    )}
  </StepNavContext.Consumer>
);
